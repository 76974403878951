//Groups constants
const Groups = Object.freeze({
    ADMIN: "Admin",
    ACCOUNT_ADMIN: "Account Admin",
    CREW_LEADER: "Crew Leader",
    GROWER: "Grower",
    EMPLOYEE: "Employee",
    PAYROLL: "Payroll",
    SUPERVISOR: "Supervisor",

    //To get group name by int
    getByInt: _int => {
        switch (_int) {
            case 1:
                return Groups.ADMIN;
            case 2:
                return Groups.ACCOUNT_ADMIN;
            case 3:
                return Groups.CREW_LEADER;
            case 4:
                return Groups.GROWER;
            case 5:
                return Groups.EMPLOYEE;
            case 6:
                return Groups.PAYROLL;
            case 7:
                return Groups.SUPERVISOR;
            default:
                return Groups.CREW_LEADER;
        }
    },

    //To get group int by name
    getByName: name => {
        switch (name) {
            case Groups.ADMIN:
                return 1;
            case Groups.ACCOUNT_ADMIN:
                return 2;
            case Groups.CREW_LEADER:
                return 3;
            case Groups.GROWER:
                return 4;
            case Groups.EMPLOYEE:
                return 5;
            case Groups.PAYROLL:
                return 6;
            case Groups.SUPERVISOR:
                return 7;
            default:
                return 3;
        }
    },

    //Helper function to create new user with groups without admin
    createUserGroups: () => {
        return [{
            value: Groups.getByName(Groups.ACCOUNT_ADMIN), label: Groups.ACCOUNT_ADMIN
        }, {
            value: Groups.getByName(Groups.CREW_LEADER), label: Groups.CREW_LEADER
        }, {
            value: Groups.getByName(Groups.GROWER), label: Groups.GROWER
        }, {
            value: Groups.getByName(Groups.EMPLOYEE), label: Groups.EMPLOYEE
        }, {
            value: Groups.getByName(Groups.PAYROLL), label: Groups.PAYROLL
        }, {
            value: Groups.getByName(Groups.SUPERVISOR), label: Groups.SUPERVISOR
        },];
    },

    //To check if user is admin or not
    isAdmin: groupIDs => {
        let _isAdmin = false;
        groupIDs.map(groupID => {
            if (!_isAdmin && Groups.getByInt(groupID) === Groups.ADMIN) {
                _isAdmin = true;
            }
            return true;
        });
        return _isAdmin;
    },

    //To check if user is supervisor and has specific access to reports
    isSupervisorUser: groupID => {
        return Groups.getByInt(groupID) === Groups.SUPERVISOR;
    },

    /*
     * NEW PERMISSIONS
     * */
    handlePermissions: (key, groupIDs) => {
        const permissions = {
            hasAccessToAccountAdd: [Groups.ADMIN],
            hasAccessToOperationEdit: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],

            hasAccessToUsers: [Groups.ADMIN, Groups.ACCOUNT_ADMIN, Groups.CREW_LEADER],
            hasAccessToUsersAdd: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],
            hasAccessToUsersManage: [Groups.ADMIN, Groups.ACCOUNT_ADMIN, Groups.CREW_LEADER],
            hasAccessToUsersUpdate: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],

            hasAccessToCrews: [Groups.ADMIN, Groups.ACCOUNT_ADMIN, Groups.CREW_LEADER],
            hasAccessToCrewsAdd: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],
            hasAccessToCrewsManage: [Groups.ADMIN, Groups.ACCOUNT_ADMIN, Groups.CREW_LEADER],
            hasAccessToCrewsUpdate: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],
            hasAccessToCrewsBulkManagement: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],

            hasAccessToDevices: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],
            hasAccessToDeviceAdd: [Groups.ADMIN],
            hasAccessToDeviceOperationDelete: [Groups.ADMIN],
            hasAccessToDeviceManage: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],
            hasAccessToDeviceEdit: [Groups.ADMIN],
            hasAccessToDeviceAddSettings: [Groups.ADMIN],
            hasAccessToDeviceViewConfigProfile: [Groups.ADMIN],

            hasAccessToWorkers: [Groups.ADMIN, Groups.ACCOUNT_ADMIN, Groups.CREW_LEADER],
            hasAccessToWorkersAdd: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],
            hasAccessToWorkersManage: [Groups.ADMIN, Groups.ACCOUNT_ADMIN, Groups.CREW_LEADER],
            hasAccessToWorkersUpdate: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],
            hasAccessToWorkersDelete: [Groups.ADMIN],
            hasAccessToWorkersBulkOperations: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],

            hasAccessToContracts: [Groups.ADMIN, Groups.ACCOUNT_ADMIN, Groups.CREW_LEADER],
            hasAccessToContractsAdd: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],
            hasAccessToContractsManage: [Groups.ADMIN, Groups.ACCOUNT_ADMIN, Groups.CREW_LEADER],
            hasAccessToContractsUpdate: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],

            hasAccessToH2AExceptions: [Groups.ADMIN, Groups.ACCOUNT_ADMIN, Groups.CREW_LEADER],
            hasAccessToH2AExceptionsAdd: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],
            hasAccessToH2AExceptionsManage: [Groups.ADMIN, Groups.ACCOUNT_ADMIN, Groups.CREW_LEADER],
            hasAccessToH2AExceptionsUpdate: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],

            hasAccessToWorkDetails: [Groups.ADMIN, Groups.ACCOUNT_ADMIN, Groups.CREW_LEADER],
            hasAccessToWorkDetailsAdd: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],
            hasAccessToWorkDetailsManage: [Groups.ADMIN, Groups.ACCOUNT_ADMIN, Groups.CREW_LEADER],
            hasAccessToWorkDetailsUpdate: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],
            hasAccessToWorkDetailsBulkOperations: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],
            hasAccessToAliasesManage: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],

            // tools permissions
            hasAccessToTools: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],
            hasAccessToAccessManager: [Groups.ADMIN],
            hasAccessToTimeSheetManager: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],
            hasAccessToH2AExceptionsManager: [Groups.ADMIN, Groups.ACCOUNT_ADMIN],
            hasAccessToV3Migration: [Groups.ADMIN],
            hasAccessToBBCardSetup: [Groups.ADMIN],

            // legacy reports permissions
            hasAccessToLegacyReports: [Groups.ADMIN, Groups.ACCOUNT_ADMIN, Groups.SUPERVISOR, Groups.PAYROLL],
            hasAccessToLegacyReportsManagement: [Groups.ADMIN],
            hasAccessToManageIWL: [Groups.SUPERVISOR],
            hasAccessToReportBuilder: [Groups.ADMIN],
            hasAccessToStatus: [Groups.ADMIN],

            // reports permissions
            hasAccessToReports: [Groups.ADMIN, Groups.ACCOUNT_ADMIN, Groups.PAYROLL],
            hasAccessReportsManagement: [Groups.ADMIN],
            hasAccessDailyReport: [Groups.ADMIN, Groups.ACCOUNT_ADMIN, Groups.PAYROLL],
        }
        let permission = false;
        groupIDs.map(id => {
            if (!permission && permissions.hasOwnProperty(key) && permissions[key].map(groupName => Groups.getByName(groupName)).includes(id)) permission = true;
            return false;
        });

        return permission;
    },
});

export default Groups;
